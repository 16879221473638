<template>
  <div v-if="$store.state.appData.pdfPreview.show" class="page_preview" :class="$store.state.appData.pdfPreview.show? $store.state.appData.pdfPreview.confirm? 'confirm show' : 'show' : '' ">
        <a-icon type="close" class="close_button cursor-pointer text-white text-xl" @click="$store.commit('CLOSE_PREVIEW')"/>
        <iframe :src="$store.state.appData.pdfPreview.data" id="iframe" frameborder="0"></iframe>
        <div class="page-preview-action dF jSB aC px-8 w-full">
          <a-button size="large" @click="$store.commit('CLOSE_PREVIEW')">Back</a-button>
          <a-button type="primary" size="large" @click="$store.commit('PAGE_PREVIEW_CALLBACK')" v-if="$store.state.appData.pdfPreview.save && $store.state.appData.pdfPreview.confirm">SAVE DOCUMENT</a-button>
          <a-button type="primary" size="large" @click="$store.commit('PAGE_PREVIEW_CALLBACK')" v-else>SEND CONTRACT</a-button>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
 .page_preview{
		position: fixed;
		left:0;
		top:0;
		width:100vw;
		height:100vh;
		background:rgba(0, 0, 0, .6);
		display:flex;
		z-index:1005;
		opacity:0;
		pointer-events: none;
		transition:opacity .3s ease-out;
    justify-content: center;
    align-items: center;
		&.show{
			opacity:1;
			pointer-events: all;
		}
		.close_button{
			position: absolute;
			top:20px;
			right:20px;
		}
		canvas,iframe{
			width: 100%;
      flex:1;
		}
    &:not(.confirm){
      .page-preview-action{
        display:none;
      }
      canvas,iframe{

        width: 80%;
        height:90%;
      }
    }
    &.confirm{
      flex-direction: column;
      background:white;
      .close_button{
        display:none;
      }
      iframe,canvas{
        box-shadow: 0 2px 30px rgba(100,100,100,0.3);
      }
    }
	}
  .page-preview-action{
    height:100px;
  }
</style>
