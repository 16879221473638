<template>
	<div id="app">
		<BhLoading :show="loading" />
		<MediaSelector />
		<PagePreview />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import PagePreview from '@/components/forms/PagePreview'
import BhLoading from 'bh-mod/components/common/Loading'

export default {
	name: 'app',
	components: { Localization, MediaSelector, BhLoading, PagePreview },
	computed: {
		...mapGetters(['user']),
		loading() {
			return this.$store.state.loading
		},
		modal() {
			return this.$store.state.appData.modal
		}
	},
	watch: {
		'$route'(to, from) {

			if (document.querySelector('.scrolling-shadow')) {
				document.querySelector('.scrolling-shadow').classList.remove('scrolling-shadow')
			}

			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
		},
	},
	methods: {

	},
	mounted() {

	}
}
</script>
<style lang="scss">
#app {
	min-height: 100vh;
}

.no-pointer {
	pointer-events: none;
}
</style>
