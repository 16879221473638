import Vue from 'vue'
import Vuex from 'vuex'
import { $api, arrToObj } from 'bh-mod'
import settings from './settings'
import { setProp, deleteProp } from 'bh-mod'
// setProp(state,['units',data.id],data)
Vue.use(Vuex)

export default {
    state: {
        filter:{
            label:'All Templates',
            value:'all'
        },
        allSettings:{},
        justUpdated:false,
        editor:null,
        settings:null,
        appSettings:{},
        templates: {},
        pdfPreview:{
            data:null,
            show:false,
            callback:false,
            confirm:false,
            save:false
        },
		deposits:[]
    },
    mutations: {
        SET_OPTIONS: (state,{type,where,action = '',what,key}) => {
            if (!type) type = 'app'
            let settings = state.allSettings[type].options
            what = JSON.parse(JSON.stringify(what))

            if (action.indexOf('array') === 0){
                if (action === 'arrayAdd'){

                    if (!settings[where]) settings[where] = []
                    settings[where].push(what)

                } else if (action === 'arrayEdit'){

                    if (!key) key = 'id'

                    if (!settings.hasOwnProperty(where) || !what.hasOwnProperty(key)) return

                    settings[where] = settings[where].map(x => x[key] === what[key] ? what : x)
                }

            } else if (!action){

                settings[where] = what

            }

        },
        SHOW_PREVIEW:(state,data) => state.pdfPreview={data,show:true,callback:false,confirm:false,save:false},
        REVIEW_SAVE:(state,{preview,callback}) => state.pdfPreview={data:preview,callback,show:true,confirm:true,save:true},
        REVIEW_SEND:(state,{preview,callback}) => state.pdfPreview={data:preview,callback,show:true,confirm:true,save:false},
        PAGE_PREVIEW_CALLBACK: state => {
            if (typeof state.pdfPreview.callback === 'function'){
                state.pdfPreview.callback()
            }
        },
        UPDATE_CONTRACTS:(state,array) => {
            array.forEach(x => {
                setProp(state,['templates',x.id], x)
            })
            state.justUpdated = true

        },
        SET_FILTER: (state,data) => {
            state.filter = data
        },
        CLOSE_PREVIEW:(state) => state.pdfPreview={data:null,show:false,},
        SET_EDITOR: (state,{editor,settings}) => {state.editor = editor; state.settings = settings},
        LOAD_PAGE: (state, status = !state.loadingPage) => state.loadingPage = status,
        SET_PROP: (state, { where, what, del = false }) => {
            if (!where.length) return
            if (del) return deleteProp(state, where)
            if (what.status === null) what.status = 'bh_lead'
            else if (typeof what.status === 'object') what.status = what.status.id

            if (what.tags && what.tags.length) {

                what.tags = what.tags.map(x => {
                    if (!state.tags[x.id] && typeof x === 'object') setProp(state, ['tags', x.id], x)
                    return x.id
                })
            }


            state.newStoreTime = Date.now()
            setProp(state, where, what)
        },
        CLOSE_MODALS: (state) => {
            state.statusModal = { type: '', show: false }
            state.formModal = { show: false, type: '' }
        },
        SET_SETTINGS(state, data) {
            let temp = data
            if (data.userApp == null) {
                temp.userApp = {
                options:{
                    seenIntro:false,
                }
                }
                state.allSettings = temp
            } else {
                state.allSettings = data
            }
        },
        SET_APPDATA(state, data) {

            // if (!data || !data.length) return null
            Object.entries(data).forEach( ([key,value]) => {
                if (Array.isArray(value) && value[0] && value[0].id) value = arrToObj(value)
                state[key] = value
            })
        },
        GET_STARTED: state => {
            state.getStarted = true
        },
		SET_DEPOSITS(state, data){
			state.deposits = data
		}
    },
    actions: {
        SET_APPDATA: ({ commit }, data) => commit('SET_APPDATA', data),
    },
    getters:{

        appSettings: state => {
            return state.allSettings.app
        },

    }
}
