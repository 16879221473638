import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import appData from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		appData,
		library,
	},
	state: {
		staticFields: {
			'static.text': {
				name: 'Text',
				type: 'text',
				color: '#FFCC00',
				value: 'static.text'
			},
			'static.image': {
				name: 'Image',
				type: 'image',
				color: '#FFCC00',
				value: 'static.image'
			},
			'static.checkmark': {
				name: 'Checkmark',
				type: 'checkmark',
				color: '#FFCC00',
				value: 'static.checkmark'
			},
		},
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		pageButtons: [],
		loading: false,
		crumbs: [],
		sidebarCollapse: false,
		fileStructure: []
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		SET_CRUMBS: (state, array) => state.crumbs = array,
		CLEAR_CRUMBS: (state) => state.crumbs = [],
		SIDEBAR_COLLAPSE: (state, is = null) => is !== null ? state.sidebarCollapse = Boolean(is) : state.sidebarCollapse = !state.sidebarCollapse,
		LOAD: (state, is = null) => is !== null ? state.loading = Boolean(is) : state.loading = !state.loading,
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		SET_INSTANCES: (state, data) => {
			state.instances = data || []
		}
	},
	getters: {
		isPremiumPackage: state => {
			return state && state.instance && state.instance.package && ["PA-COM-LRP", "PA-CON-HRP"].includes(state.instance.package.itemCode)
		}
	},
	actions: {



	},
})
